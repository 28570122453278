import { ColorSchemes } from "@/utils/colorSchemes";

export type Folder = {
  id: number;
  name: string;
  slug: string;
  path?: string;
  parentFolderId?: Folder["id"];
  parentFolder?: Folder;
  files: File[];
  folders?: Folder[];
};

export type File = {
  createdAt: Date;
  folder: Omit<Folder, "files">;
  name: string;
  filename: string;
  originalname: string;
  extension: string;
  mimetype: string;
  filesize: number;
  type?: string;
  id: number;
  tags: Tag[];
  tagIds: number[];
  path?: string;
};

export type TagGroup = {
  name: string;
  colorScheme: ColorSchemes;
};

export type Tag = {
  id: number;
  name: string;
  picture: string;
  hashtag?: string;
  tagGroup?: TagGroup;
};

export enum CloudType {
  PHOTO = "photo",
  VIDEO = "video",
  AUDIO = "audio",
  OTHER = "other",
}
