import useIsMobile from "@/hooks/useIsMobile";
import { useUser } from "@/hooks/useUser";
import { CloudType, File } from "@/types/cloud";
import { formatDurationTime } from "@/utils/dateTime";
import {
  Box,
  Button,
  Flex,
  Icon,
  Progress,
  Tooltip,
  chakra,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import FileSaver from "file-saver";
import getConfig from "next/config";
import React, { MouseEvent, useCallback, useEffect, useRef } from "react";
import { DownloadCloud, Maximize, Minimize, Pause, Play } from "react-feather";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useTranslation } from "react-i18next";

let movedTimeout: NodeJS.Timeout;
let tappedTimeout: NodeJS.Timeout;

const StyledFullScreen = styled(FullScreen)`
  height: 100%;
  max-height: 100%;
`;

const VideoPlayer = ({
  file,
  stopPlayer = false,
  autoStart = true,
  noEvents = false,
  rounded = true,
  isWebsite = false,
  downloadAvailable = false,
}: {
  stopPlayer?: boolean;
  autoStart?: boolean;
  noEvents?: boolean;
  rounded?: boolean;
  isWebsite?: boolean;
} & (
  | {
      downloadAvailable: true;
      file: File;
    }
  | {
      downloadAvailable?: false;
      file: File | Pick<File, "type" | "filename">;
    }
)): JSX.Element => {
  const audioVideoRef = useRef<HTMLAudioElement & HTMLVideoElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);
  const timeTooltipRef = useRef<HTMLDivElement>(null);
  const fullscreen = useFullScreenHandle();
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [duration, setDuration] = React.useState(0);
  const [currentTime, setCurrentTime] = React.useState(0);
  const [isMoved, setIsMoved] = React.useState(false);
  const [timeTooltipVisible, setTimeTooltipVisible] = React.useState(false);
  const [timeTooltipLeft, setTimeTooltipLeft] = React.useState(50);
  const [timeTooltipValue, setTimeTooltipValue] = React.useState("00:00");
  const [showTimeLeft, setShowTimeLeft] = React.useState(false);
  const [screenTapped, setScreenTapped] = React.useState(false);
  const [session] = useUser();
  const { t } = useTranslation("common");
  const { publicRuntimeConfig } = getConfig();
  const isMobile = useIsMobile();

  const saveFile = useCallback(() => {
    if (downloadAvailable && session?.user?.id) {
      const fileName = file?.name || file?.originalname;
      const fileId = isWebsite ? file?.filename : file?.id;

      FileSaver.saveAs(
        `${publicRuntimeConfig.basePath}/cloud/file/${fileId}`,
        fileName
      );
    }
  }, [downloadAvailable, session]);

  const iconProps = {
    size: 18,
    style: {
      color: "#000",
    },
  };

  const downloadIconProps = {
    size: 18,
  };

  const playPauseIconProps = {
    ...iconProps,
    style: {
      ...iconProps.style,
      transition: "opacity .15s, transform .15s",
      transform: `translateY(${isPlaying ? "-18px" : "0"})`,
    },
  };

  const playIconProps = React.useMemo(
    () => ({
      ...playPauseIconProps,
      opacity: isPlaying ? 0 : 1,
    }),
    [isPlaying]
  );

  const pauseIconProps = React.useMemo(
    () => ({
      ...playPauseIconProps,
      opacity: isPlaying ? 1 : 0,
    }),
    [isPlaying]
  );

  useEffect(() => {
    if (autoStart) {
      togglePlaying();
    }
  }, []);

  useEffect(() => {
    setCurrentTime(0);

    if (autoStart) {
      audioVideoRef.current.play();

      setIsPlaying(true);
    } else {
      setIsPlaying(false);

      audioVideoRef.current.pause();
    }
  }, [file]);

  useEffect(() => {
    if (!stopPlayer) return;

    audioVideoRef.current.pause();
    setIsPlaying(false);
  }, [stopPlayer]);

  const togglePlaying = (e?: MouseEvent) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    if (screenTapped) {
      clearTimeout(tappedTimeout);
      tappedTimeout = setTimeout(() => setScreenTapped(false), 3000);
    }
    if (isPlaying) {
      audioVideoRef.current.pause();
    } else {
      audioVideoRef.current.play();
    }

    setIsPlaying(!isPlaying);
  };

  const progress = React.useMemo(
    () => (currentTime / duration) * 100,
    [duration, currentTime]
  );

  const formattedTime = React.useMemo(
    () =>
      currentTime
        ? formatDurationTime(
            showTimeLeft ? duration - currentTime : currentTime
          )
        : showTimeLeft
        ? formatDurationTime(duration)
        : "0:00",
    [currentTime, showTimeLeft]
  );

  const formattedDuration = React.useMemo(
    () => (duration ? formatDurationTime(duration) : "0:00"),
    [duration]
  );

  const onMouseMove = () => {
    setIsMoved(true);
    clearTimeout(movedTimeout);

    movedTimeout = setTimeout(
      () => {
        setIsMoved(false);
        setTimeTooltipVisible(false);
      },
      !isMobile ? 3000 : 1000
    );
  };

  const getProgressX = (e: MouseEvent) => {
    const barRect = e.currentTarget.getBoundingClientRect();

    return e.clientX - barRect.left;
  };

  const onProgressClick = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    const progressRect = progressRef.current.getBoundingClientRect();
    const x = getProgressX(e) / (progressRect.right - progressRect.left);

    audioVideoRef.current.currentTime = duration * x;
  };

  const onProgressMouseEnter = () => {
    setTimeTooltipVisible(true);
  };

  const onProgressMouseLeave = () => {
    setTimeTooltipVisible(false);
  };

  const onProgressMouseMove = (e) => {
    if (timeTooltipVisible) {
      const left = getProgressX(e);
      const progressRect = progressRef.current.getBoundingClientRect();
      const x = getProgressX(e) / (progressRect.right - progressRect.left);

      setTimeTooltipLeft(progressRef.current.offsetLeft + left);
      setTimeTooltipValue(formatDurationTime(duration * x));
    }
  };

  const playerProps = {
    src: `${publicRuntimeConfig.basePath}/cloud/file/${file?.filename}`,
    ref: audioVideoRef,
    onLoadedMetadata: (e: {
      currentTarget: { duration: React.SetStateAction<number> };
    }) => setDuration(e.currentTarget.duration),
    onTimeUpdate: (e: {
      currentTarget: { currentTime: React.SetStateAction<number> };
    }) => setCurrentTime(e.currentTarget.currentTime),
    onEnded: () => {
      setCurrentTime(0);
      setIsPlaying(false);
    },
    style: {
      maxWidth: "100%",
      maxHeight: fullscreen.active ? "100vh" : "100%",
      zIndex: 1,
      marginLeft: "auto",
      marginRight: "auto",
    },
  };

  const playerCoverProps = {
    ...(!noEvents || (noEvents && fullscreen.active)
      ? {
          onClick: (e) => {
            e.stopPropagation();
            if (isMobile) {
              if (screenTapped) {
                setScreenTapped(false);
                setIsMoved(false);
              } else {
                setScreenTapped(true);
                clearTimeout(tappedTimeout);
                tappedTimeout = setTimeout(() => setScreenTapped(false), 3000);
              }
            } else {
              togglePlaying();
            }
          },
          onDoubleClick: (e) => {
            e.stopPropagation();
            fullscreen.active ? fullscreen.exit() : fullscreen.enter();
          },
        }
      : {}),
  };

  const isVideo = React.useMemo(() => file?.type === CloudType.VIDEO, [file]);
  const isAudio = React.useMemo(() => file?.type === CloudType.AUDIO, [file]);

  return (
    <StyledFullScreen handle={fullscreen}>
      <Flex
        direction="column"
        position="relative"
        onMouseMove={() => {
          if (fullscreen.active) {
            onMouseMove();
          }
        }}
        height="100%"
        maxH="100%"
        justifyContent="center"
        onClick={(e) => {
          if (!fullscreen.active) return;
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {file?.type === CloudType.VIDEO ? (
          <div style={{ maxHeight: "100%", background: "#000000" }}>
            <video {...playerProps} />
          </div>
        ) : file?.type === CloudType.AUDIO ? (
          <audio {...playerProps} />
        ) : null}
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom="44px"
          zIndex={999}
          {...playerCoverProps}
        />
        <Button
          style={
            fullscreen.active && screenTapped && isMobile
              ? {
                  width: 50,
                  height: 50,
                  position: "absolute",
                  top: "calc(50% - 25px)",
                  left: "calc(50% - 25px)",
                  zIndex: 999,
                  borderRadius: 25,
                }
              : {
                  display: "none",
                }
          }
          onClick={() => {
            togglePlaying();
          }}
        >
          {isPlaying ? (
            <Icon as={Pause} w="25px" h="25px" />
          ) : (
            <Icon as={Play} w="25px" h="25px" ml="3px" />
          )}
        </Button>
        <Box
          style={
            fullscreen.active
              ? {
                  position: "fixed",
                  width: "100%",
                  bottom: "0",
                  transition: "transform .5s",
                  transform: isMoved ? "translateY(0)" : "translateY(100%)",
                  zIndex: 2,
                }
              : { position: "relative" }
          }
        >
          <Box
            ref={timeTooltipRef}
            style={{
              position: "absolute",
              left: `${+timeTooltipLeft}px`,
              top: "1px",
              padding: ".05rem .35rem",
              color: "#000",
              fontSize: ".75rem",
              fontWeight: "bold",
              transition: "transform .15s",
              transform: `translate(-50%,${
                timeTooltipVisible ? "-100%" : "0"
              })`,
              borderTopLeftRadius: rounded ? ".375rem" : 0,
              borderTopRightRadius: rounded ? ".375rem" : 0,
              background: "#FFFFFF",
              zIndex: 1,
            }}
          >
            {timeTooltipValue}
          </Box>
          <Flex
            p={2}
            justifyContent="stretch"
            alignItems="center"
            style={{
              background: "#FFF",
              ...{
                position: "relative",
                borderBottomLeftRadius: rounded ? ".375rem" : 0,
                borderBottomRightRadius: rounded ? ".375rem" : 0,
                borderTopLeftRadius: isAudio && rounded ? ".375rem" : 0,
                borderTopRightRadius: isAudio && rounded ? ".375rem" : 0,
                zIndex: 2,
              },
            }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Box style={{ width: "18px", height: "18px", cursor: "pointer" }}>
              <Play {...playIconProps} onClick={togglePlaying} />
              <Pause {...pauseIconProps} onClick={togglePlaying} />
            </Box>
            <Box
              ref={progressRef}
              {...(isVideo ? { flex: "1" } : { width: "100%" })}
              mx={3}
              py={2}
              onClick={onProgressClick}
              onMouseEnter={onProgressMouseEnter}
              onMouseLeave={onProgressMouseLeave}
              onMouseMove={onProgressMouseMove}
            >
              <Progress
                value={progress || 0}
                isIndeterminate={!duration}
                width="100%"
              />
            </Box>
            <Box
              style={{ color: "#000" }}
              mr={3}
              cursor="pointer"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setShowTimeLeft(!showTimeLeft);
              }}
            >
              {formattedTime}&nbsp;/&nbsp;{formattedDuration}
            </Box>
            {downloadAvailable && (
              <Tooltip
                hasArrow
                label={t("cloud.unauthorizedDownload")}
                placement="top"
                isDisabled={session?.user?.id ? true : false}
              >
                <Box
                  mr={3}
                  color={session?.user?.id ? "black" : "gray.300"}
                  cursor={session?.user?.id ? "pointer" : undefined}
                >
                  <DownloadCloud {...downloadIconProps} onClick={saveFile} />
                </Box>
              </Tooltip>
            )}
            {isVideo && (
              <>
                {fullscreen.active ? (
                  <Minimize
                    {...iconProps}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      fullscreen.exit();
                    }}
                    cursor="pointer"
                  />
                ) : (
                  <Maximize
                    {...iconProps}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();

                      fullscreen.enter();
                      onMouseMove();
                    }}
                    cursor="pointer"
                  />
                )}
              </>
            )}
          </Flex>
        </Box>
      </Flex>
    </StyledFullScreen>
  );
};

export default VideoPlayer;
