/** https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/button.ts */

import { useColorModeValue } from "@chakra-ui/color-mode";
import { useTheme } from "@chakra-ui/react";

export type ColorSchemes =
  | "gray"
  | "red"
  | "orange"
  | "yellow"
  | "green"
  | "teal"
  | "blue"
  | "cyan"
  | "purple"
  | "pink";

type AccessibleColor = {
  bg?: string;
  color?: string;
  hoverBg?: string;
  activeBg?: string;
};

/** Accessible color overrides for less accessible colors. */
const accessibleColorMap: { [key: string]: AccessibleColor } = {
  yellow: {
    bg: "yellow.400",
    color: "black",
    hoverBg: "yellow.500",
    activeBg: "yellow.600",
  },
  cyan: {
    bg: "cyan.400",
    color: "black",
    hoverBg: "cyan.500",
    activeBg: "cyan.600",
  },
};

export const colorSchemes = (c: ColorSchemes, staticOnly = false) => {
  if (c === "gray") {
    const bg = useColorModeValue(`gray.100`, `whiteAlpha.200`);

    return {
      bg,
      ...(!staticOnly
        ? {
            _hover: {
              bg: useColorModeValue(`gray.200`, `whiteAlpha.300`),
              _disabled: {
                bg,
              },
            },
            _active: { bg: useColorModeValue(`gray.300`, `whiteAlpha.400`) },
          }
        : {}),
    };
  }

  const {
    bg = `${c}.500`,
    color = "white",
    hoverBg = `${c}.600`,
    activeBg = `${c}.700`,
  } = accessibleColorMap[c] ?? {};

  const background = useColorModeValue(bg, `${c}.200`);

  return {
    bg: background,
    ...(!staticOnly
      ? {
          color: useColorModeValue(color, `gray.800`),
          _hover: {
            bg: useColorModeValue(hoverBg, `${c}.300`),
            _disabled: {
              bg: background,
            },
          },
          _active: { bg: useColorModeValue(activeBg, `${c}.400`) },
        }
      : {}),
  };
};

export const transformColor = (chakraColor: string): string => {
  const theme = useTheme();
  const colorParts = chakraColor.split(".");

  return theme.colors[colorParts[0]][colorParts[1]];
};

export const colorStyles = (
  c: ColorSchemes,
  { isSelected, isFocused }: { isSelected: boolean; isFocused: boolean }
) => {
  const colors = colorSchemes(c);

  return {
    color: isSelected
      ? transformColor(colors.color)
      : isFocused
      ? transformColor(colors.color)
      : transformColor(colors.color),
    backgroundColor: isSelected
      ? transformColor(colors._active.bg)
      : isFocused
      ? transformColor(colors._active.bg)
      : transformColor(colors.bg),
    ":active": {
      backgroundColor: isSelected
        ? transformColor(colors._active.bg)
        : isFocused
        ? transformColor(colors._active.bg)
        : transformColor(colors.bg),
    },
  };
};
