import { intervalToDuration } from "date-fns";

const timePad = (timePart: number) => timePart.toString().padStart(2, "0");

export const formatDurationTime = (
  seconds: number,
  forceHours = false
): string => {
  const timeParts = intervalToDuration({ start: 0, end: seconds * 1000 });

  if (timeParts.hours > 0 || forceHours) {
    return `${timeParts.hours}:${timePad(timeParts.minutes)}:${timePad(
      timeParts.seconds
    )}`;
  }

  return `${timeParts.minutes}:${timePad(timeParts.seconds)}`;
};
